import { Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "theme-ui";
import { theme } from "../../theme";

export const LedTrunkLightning = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box>
      <Card>
        <CardHeader title={<Typography variant="h6" fontSize={16}>UPGRADE LED TRUNKBELEUCHTUNG</Typography>} />
        <CardMedia sx={{ paddingBottom: "50%", position: "relative" }}>
          <Box sx={{ position: "absolute", inset: 0 }}>
            <img
              src={"/images/services/service4.webp"}
              alt={""}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <Box
              sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
              bg={"rgba(0,0,0,0.6)"}
              color={theme.colors.white}
              p={theme.space["3"]}
            >
              Tesla Model 3
            </Box>
          </Box>
        </CardMedia>
        <CardContent>
          <Box>
            Der Trunk (Kofferraum) des Model 3 ist wortwörtlich eine eher dunkle Angelegenheit - für CHF 130.- inklusive
            Arbeit beleuchten wir Ihren Trunk mit einem 60 Centimeter langen LED-Streifen und bringen so "Licht ins
            Dunkle".
          </Box>
          <Collapse in={expanded} timeout={"auto"}>
            <Box>
              Tesla wechselt den alten Türgriff gegen die neuste Generation (mit weniger Problemen) aus für CHF 400.- +.
              Wir reparieren Ihren alten Türgriff, das ist nicht nur günstiger, sondern auch nachhaltiger.
            </Box>
          </Collapse>
        </CardContent>
        <CardActions>
          <IconButton onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
};
