import { Box, Container, Flex } from "theme-ui";
import { DoorHandleRepairModelS } from "./DoorHandleRepairModelS";
import { Navigation } from "../../components/navigation/Navigation";
import { theme } from "../../theme";
import { PowerliftUpgradeTrunk } from "./PowerliftUpgradeTrunk";
import { RearSpoiler } from "./RearSpoiler";
import { LedTrunkLightning } from "./LedTrunkLightning";
import { InductionMobileCharging } from "./InductionMobileCharging";
import { Footer } from "../../components/footer/Footer";
import { H1 } from "../../components/typography";

export const Services = () => {
  return (
    <Box>
      <Navigation />
      <Container sx={{ maxWidth: theme.sizes.container }} my={[theme.space["6"], theme.space["12"]]}>
        <Box sx={{ textAlign: "center" }} mb={[theme.space["6"], theme.space["12"]]}>
          <H1>Unsere Dienstleistungen für Sie</H1>
        </Box>
        <Flex sx={{ flexWrap: "wrap", gap: "18px", justifyContent: "center" }}>
          <Box sx={{ width: ["100%", "50%", "calc(33.33% - 12px)"] }}>
            <DoorHandleRepairModelS />
          </Box>
          <Box sx={{ width: ["100%", "50%", "calc(33.33% - 12px)"] }}>
            <PowerliftUpgradeTrunk />
          </Box>
          <Box sx={{ width: ["100%", "50%", "calc(33.33% - 12px)"] }}>
            <RearSpoiler />
          </Box>
          <Box sx={{ width: ["100%", "50%", "calc(33.33% - 12px)"] }}>
            <LedTrunkLightning />
          </Box>
          <Box sx={{ width: ["100%", "50%", "calc(33.33% - 12px)"] }}>
            <InductionMobileCharging />
          </Box>
        </Flex>
      </Container>
      <Footer />
    </Box>
  );
};
