import { Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "theme-ui";
import { theme } from "../../theme";

export const DoorHandleRepairModelS = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" fontSize={16}>
              TÜRGRIFF REPARIEREN
            </Typography>
          }
        />
        <CardMedia sx={{ paddingBottom: "50%", position: "relative" }}>
          <Box sx={{ position: "absolute", inset: 0 }}>
            <img
              src={"/images/services/service1.webp"}
              alt={""}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <Box
              sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
              bg={"rgba(0,0,0,0.6)"}
              color={theme.colors.white}
              p={theme.space["3"]}
            >
              Tesla Model S
            </Box>
          </Box>
        </CardMedia>
        <CardContent>
          <Box>
            Bei den Vorfaceliftmodellen des Model S (bis Baujahr 2017) sind die Türgriffe eine der grossen
            Schwachstellen. Die Türgriffe fahren nicht mehr aus und Sie können die Türe nicht mehr von aussen öffnen -
            wir helfen Ihnen gerne.
          </Box>
          <Collapse in={expanded} timeout={"auto"}>
            <Box my={theme.space["3"]}>
              Tesla wechselt den alten Türgriff gegen die neuste Generation (mit weniger Problemen) aus für CHF 400.- +.
              Wir reparieren Ihren alten Türgriff, das ist nicht nur günstiger, sondern auch nachhaltiger.
            </Box>
            <Box mb={theme.space["3"]}>
              Die Reparatur der Türgriffe kostet pauschal CHF 220.- und dauert etwa eine Stunde - Preis gilt für
              Türgriffe der ersten Generation (V1)
            </Box>
            <Box>
              Wir haben zudem auch Erfahrung in der Reparatur neuerer Türgriffe (V2 und sogar bereits V3) - die Preise
              dafür liegen zwischen CHF 250.- und CHF 350.-
            </Box>
          </Collapse>
        </CardContent>
        <CardActions>
          <IconButton onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
};
