import { Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "theme-ui";
import { theme } from "../../theme";

export const PowerliftUpgradeTrunk = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" fontSize={16}>
              POWERLIFT UPGRADE FRUNK / TRUNK
            </Typography>
          }
        />
        <CardMedia sx={{ paddingBottom: "50%", position: "relative" }}>
          <Box sx={{ position: "absolute", inset: 0 }}>
            <img
              src={"/images/services/service2.webp"}
              alt={""}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <Box
              sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
              bg={"rgba(0,0,0,0.6)"}
              color={theme.colors.white}
              p={theme.space["3"]}
            >
              Tesla Model 3
            </Box>
          </Box>
        </CardMedia>
        <CardContent>
          <Box>
            Bei der ersten Tesla Model 3 Generation lassen sich sowohl der hintere Kofferraum (Trunk) als auch der
            vordere Kofferraum (Frunk) zwar über die App / von aus dem Fahrzeug entriegeln, öffnen sich jedoch nicht
            automatisch.
          </Box>
          <Collapse in={expanded} timeout={"auto"}>
            <Box my={theme.space["3"]}>Hier können wir Abhilfe schaffen!</Box>
            <Box mb={theme.space["3"]}>
              Mit dem Powerlift Upgrade statten wir Ihr Model 3 mit neuen Gasdruckfedern aus und der Frunk und oder
              Trunk öffnet sich in Zukunft auf Knopfdruck automatisch und mechanisch.
            </Box>
            <Box>
              Die Einbaukosten inklusive Material belaufen sich auf CHF 90.- pro Kofferraum oder CHF 150.- für Trunk und
              Frunk zusammen. Der Einbau dauert circa eine halbe Stunde
            </Box>
          </Collapse>
        </CardContent>
        <CardActions>
          <IconButton onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
};
