import MenuIcon from "@mui/icons-material/Menu";
import PhoneIcon from "@mui/icons-material/Phone";
import styled from "styled-components";
import { Box, Flex } from "theme-ui";
import { DesktopUp, TabletDown } from "../../responsive";
import { NavLink } from "react-router-dom";
import { theme } from "../../theme";
import { Divider, Drawer } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const navItems = [
  {
    to: "/",
    label: "Home",
  },
  {
    to: "/fahrzeugangebot",
    label: "Fahrzeugangebot",
  },
  {
    to: "/dienstleistungen",
    label: "Dienstleistungen & Tuning",
  },
  {
    to: "/wissenswertes",
    label: "Wissenswertes",
  },
  {
    to: "/tesla-mieten",
    label: "Tesla mieten",
  },
];

export const Navigation = () => {
  const [open, setOpen] = useState(false);

  const subNavLinkStyle = {
    style: {
      color: theme.colors.white,
      textDecoration: "none",
    },
  };
  const navLinkStyle = {
    style: {
      color: theme.colors.black,
      textDecoration: "none",
    },
  };
  return (
    <>
      <Flex
        bg={"#1e1e1e"}
        py={theme.space["2"]}
        sx={{ justifyContent: "end", alignItems: "center", fontSize: "14px", color: theme.colors.white }}
      >
        {/* <Box>
          <NavLink to={"/wissenswertes"} {...subNavLinkStyle}>
            Wissenswertes
          </NavLink>
        </Box> */}
        <Box px={theme.space["4"]}>
          <NavLink to={"/kontakt"} {...subNavLinkStyle}>
            Kontaktiere uns
          </NavLink>
        </Box>
        <Box>|</Box>
        <a href="tel:0799198101" {...subNavLinkStyle}>
          <Flex px={theme.space["4"]} sx={{ alignItems: "center" }}>
            <Flex mr={theme.space["1"]}>
              <PhoneIcon fontSize="small" />
            </Flex>
            <Box>0799198101</Box>
          </Flex>
        </a>
      </Flex>
      <Flex
        bg={"rgba(255,255,255,.8)"}
        px={theme.space["4"]}
        py={theme.space["2"]}
        sx={{
          height: ["50px", "60px"],
          position: "sticky",
          top: 0,
          zIndex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.colors.gray["200"]}`,
        }}
      >
        <Box sx={{ width: [180, 200, 260] }}>
          <img src={"/images/logo.webp"} alt="logo" />
        </Box>
        <DesktopUp>
          <Flex>
            {navItems.map((item) => (
              <NavItem key={item.label}>
                <NavLink to={item.to} {...navLinkStyle}>
                  {item.label}
                </NavLink>
              </NavItem>
            ))}
          </Flex>
        </DesktopUp>
        <TabletDown>
          <Box onClick={() => setOpen(true)}>
            <MenuIcon />
          </Box>
        </TabletDown>
      </Flex>
      <Drawer
        PaperProps={{
          sx: { width: "300px" },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box>
          <Flex p={theme.space["4"]} sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Flex sx={{ width: 180 }}>
              <img src={"/images/logo.webp"} alt="logo" />
            </Flex>
            <Flex onClick={() => setOpen(false)}>
              <CloseIcon fontSize="medium" />
            </Flex>
          </Flex>
          <Divider />
        </Box>
      </Drawer>
    </>
  );
};

const NavItem = styled(Box)`
  padding: 0 8px;
  text-transform: uppercase;
`;
